"use client";

import React from "react";

import { LazyMotion, domAnimation } from "framer-motion";
import "swiper/css";
import "./src/styles/global.css";

import { Script } from "gatsby";
import { ThemeProvider } from "./src/contexts/theme-provider";

// export const onServiceWorkerUpdateReady = () => {
//   const answer = window.confirm(
//     `This application has been updated. ` +
//       `Reload to display the latest version?`
//   );

//   if (answer === true) {
//     window.location.reload();
//   }
// };
// trigger an immediate page refresh when an update is found
export const onServiceWorkerUpdateReady = () => window.location.reload();

export const wrapRootElement = ({ element }) => (
  <ThemeProvider defaultTheme="system">
    <LazyMotion features={domAnimation}>{element}</LazyMotion>
    <Script
      id="hotjar-script"
      strategy="idle" // Using lazyOnload strategy
    >
      {`
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3453058,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
    </Script>
  </ThemeProvider>
);
